import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

export default (props) => {
  const { pageContext: { redirect } } = props;
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              siteUrl
            }
          }
        }
      `}
      render={data => (
        <Helmet>
          <meta
            // eslint-disable-next-line
            http-equiv="refresh"
            content={`0;url=${data.site.siteMetadata.siteUrl}${redirect}`}
          />
        </Helmet>
      )}
    />
  );
};
